import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("NotificationsChannel connected");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("NotificationsChannel disconnected");
  },

  received(data) {
    // console.log("NotificationsChannel received");
    // console.log(data);
    if (!!push_notification_from_cable){
      push_notification_from_cable(data);
      Toast.fire({
        icon: 'info',
        iconColor: 'var(--yellow)',
        title: `<span class="text-yellow">${data.title}</span><br/>${data.body}`
      });
    }
  }
})

window.consumer = consumer;